<template>
<section>
<h2 class="titulo"><strong>Nosso Portfólio</strong></h2>
<div>
<p style="text-align: center;margin-bottom:1rem!important;">Se você está procurando por serviços de instalação de redes e telas de proteção, a<strong>Campinas Redes de Proteção</strong>é a escolha certa para você! Nós temos uma vasta experiência na área e podemos garantir a segurança e proteção da sua casa ou empresa.</p>
<p style="text-align: center;margin-bottom:1rem!important;">Nós temos orgulho de apresentar alguns dos nossos trabalhos realizados para mostrar o nosso compromisso com a excelência. Desde a instalação de redes em janelas, sacadas, piscinas, até telas de proteção em escadas e varandas, nós somos capazes de atender a todas as suas necessidades de segurança.</p>
<p style="text-align: center;margin-bottom:1rem!important;">E não se preocupe, nossos serviços são acessíveis e não comprometem a qualidade. Entre em contato conosco hoje mesmo para solicitar um orçamento sem compromisso. Teremos o prazer em ajudá-lo a proteger o que mais importa para você!</p>
</div>
<div class="gal">
<img src='@/assets/portfolio/p-01.webp' alt="" width="0" height="0"/>
<img src='@/assets/portfolio/p-02.webp' alt="" width="0" height="0"/>
<img src='@/assets/portfolio/p-03.webp' alt="" width="0" height="0"/>
<img src='@/assets/portfolio/p-04.webp' alt="" width="0" height="0"/>
<img src='@/assets/portfolio/p-05.webp' alt="" width="0" height="0"/>
<img src='@/assets/portfolio/p-06.webp' alt="" width="0" height="0"/>
<img src='@/assets/portfolio/p-07.webp' alt="" width="0" height="0"/>
<img src='@/assets/portfolio/p-08.webp' alt="" width="0" height="0"/>
<img src='@/assets/portfolio/p-09.webp' alt="" width="0" height="0"/>
<img src='@/assets/portfolio/p-10.webp' alt="" width="0" height="0"/>
<img src='@/assets/portfolio/p-11.webp' alt="" width="0" height="0"/>
<img src='@/assets/portfolio/p-12.webp' alt="" width="0" height="0"/>
<img src='@/assets/portfolio/p-13.webp' alt="" width="0" height="0"/>
<img src='@/assets/portfolio/p-14.webp' alt="" width="0" height="0"/>
<img src='@/assets/portfolio/p-15.webp' alt="" width="0" height="0"/>
</div>
</section>
</template>
<style lang="scss" scoped>
section{
  margin:7rem 0!important;
}
.gal{
img{
min-width:100%!important;
max-width:100%!important;
width: auto;
height: auto
}
max-width: 80vw!important;
// background: red;
margin:0 auto!important;
display: grid;
grid-template-columns: repeat(5,calc((80vw - 40px)/5));
grid-column-gap: 10px;
grid-row-gap: 10px;
@media only screen and (max-width: 600px) {
max-width: 95vw!important;
grid-template-columns: repeat(2,calc((95vw - 10px)/2))
}
.product-item{
background: #fff
}
}
</style>
